<template lang="pug">
  include ../plugins/pug/btn

  div.casespage.second__page
    div.custom__loader
    PageHeaderBlock(
      :header-img="WallVacancies"
      :header-title="'Vacancies'"
      :header-content="`Join our dynamic team and shape the future with us. Discover exciting roles across various domains at Disoft`"
    )
    section.section
      div.container
        div(:class="{ 'active': toggleClass }").vacancies__popup
          div(@click="toggleFullSize").layer
          div.vacancies
            div(v-if="allVacancies.length").vacancies__accordion
              div.vacancies__accordion-top
                span
              div.accordion__inner
                v-collapse-group
                  v-collapse-wrapper(
                    v-for="(direction, index) in uniqueDirections"
                    :key="index"
                    :active="true"
                  ).vacancies__accordion-item
                    div(v-collapse-toggle).accordion__title {{ direction }}
                    ul(v-collapse-content).accordion__content
                      li(
                        v-for="(vacancy, vIndex) in filteredVacancies(index)"
                        :key="vIndex"
                        :class="{ active: selectedVacancy && selectedVacancy.vacancy === vacancy.vacancy }"
                        @click="selectVacancy(index, vIndex)"
                        v-scroll-to="'#vacancies__content'"
                      ).test {{ vacancy.vacancy }}
            div#vacancies__content.vacancies__content
              div.vacancies__content-top
                div.vacancies__top-title {{ selectedDirection !== null ? uniqueDirections[selectedDirection] : '' }}
                button(@click="toggleFullSize").full__size
              div(ref="contentInner").vacancies__content-inner
                div.vacancies__content-header
                  h2.header__title.h2.fw-700 {{ selectedVacancy ? selectedVacancy.vacancy : '' }}
                  button(@click="openPopup").btn.header__btn
                    span Contact
                div(
                  v-if="selectedVacancy && selectedVacancy.job_description"
                  v-html="selectedVacancy.job_description"
                ).simple__page
    div(
      v-show="isShowPopup"
      :class="{ 'popup form__popup active': isShowPopup}"
    )
      div(@click="removePopup").layer
      div.dialog__window.feedback.popup__type
        div.dialog__window-top
          span
          div(@click="removePopup").close
            div.line.one
            div.line.two
        vacancies-form(@updateIsShowPopup="updateIsShowPopup")

    ThanksPopup(ref="thanksPopup")
</template>

<script>
// images
import WallVacancies from '@/assets/icons/wall-vacancies.svg'
export default {
  name: 'VacanciesPage',
  components: {
    PageHeaderBlock: () => import('@/components/PageHeaderBlock'),
    VacanciesForm: () => import('@/components/VacanciesForm'),
    ThanksPopup: () => import('@/components/ThanksPopup')
  },
  data () {
    return {
      WallVacancies,
      isShowPopup: false,
      toggleClass: false,
      selectedDirection: null,
      selectedVacancy: null,
      allVacancies: [],
      activeDirectionIndex: null,
      activeVacancyIndex: null
    }
  },
  computed: {
    uniqueDirections () {
      return [...new Set(this.allVacancies.map(vacancy => vacancy.select_direction))]
    }
  },
  mounted () {
    this.fetchVacancies()
  },
  methods: {
    async fetchVacancies () {
      try {
        const response = await this.$api.get(`${ process.env.VUE_APP_API }/items/vacancies?[_eq]=published`)
        this.allVacancies = response.data.data
        this.loadSelectedVacancy()
      } catch (error) {
        console.error('Error:', error)
      }
    },
    openPopup () {
      this.isShowPopup = true
    },
    removePopup () {
      this.isShowPopup = false
    },
    toggleFullSize () {
      this.toggleClass = !this.toggleClass
    },
    filteredVacancies (directionIndex) {
      const direction = this.uniqueDirections[directionIndex]
      return this.allVacancies.filter(vacancy => vacancy.select_direction === direction)
    },
    selectVacancy (directionIndex, vacancyIndex) {
      this.selectedDirection = directionIndex
      this.selectedVacancy = this.filteredVacancies(directionIndex)[vacancyIndex]
      this.activeDirectionIndex = directionIndex
      this.activeVacancyIndex = vacancyIndex

      // add anchor
      const anchor = this.selectedVacancy.anchor
      const newURL = `#${anchor}`
      window.location.hash = newURL

      // scroll to content
      this.$refs.contentInner?.scrollTo({ top: 0, behavior: 'smooth' })
    },
    loadSelectedVacancy () {
      const anchor = window.location.hash.substring(1)
      const selectedVacancy = this.allVacancies.find(vacancy => vacancy.anchor === anchor)

      if (selectedVacancy) {
        this.selectedVacancy = selectedVacancy
      } else if (this.allVacancies.length > 0) {
        this.selectedDirection = 0
        this.selectedVacancy = this.allVacancies[0]
      }
    },
    updateIsShowPopup (value) {
      this.isShowPopup = value
      this.$refs.thanksPopup.waitPopup()
    }
  }
}
</script>
